<template>
  <v-layout row wrap>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <h3>Information</h3>
      <p>BPI App &copy; {{ new Date().getFullYear() }} BPI Services B.V.</p>
      <p>
        Running on <a target="_blank" href="https://docs.microsoft.com/en-us/aspnet/core/?view=aspnetcore-2.2">ASP.NET Core</a>, 
        built with <a target="_blank" href="https://vuejs.org/">VueJS</a> and <a target="_blank" href="https://vuetifyjs.com/">VuetifyJS</a>
        and protected by <a target="_blank" href="https://identityserver.io/">IdentityServer</a>
      </p>
      <h3>Release notes</h3>
      <p></p>
      <ul>
        <li>
          v1.3 - December 2021
          <ul>
            <li>API uitbreiding voor Bold</li>
          </ul><p></p>
        </li>
        <li>
          v1.2 - Maart 2020
          <ul>
            <li>.netcore 3.1</li>
            <li>Omschrijving veld optioneel voor administratieve projecten</li>
          </ul><p></p>
        </li>
        <li>
          v1.1 - April 2019
          <ul>
            <li>Het selecteren van een datum in de toekomst werkt nu ook vanuit de startdatum</li>
            <li>In de timesheet werkt de geselecteerde dag consistenter en wordt deze aangegeven met een pijl</li>
            <li>Het urenboekingformulier toont een slider op telefoons en anders een tekst invoerveld</li>
            <li>Het bijwerken van het aantal in te voeren uren wordt nu goed uitgevoerd</li>
            <li>Urenboekingen kunnen aangepast worden vanuit de urenlijst rapporten</li>
            <li>De administrator kan uren van medewerkers corrigeren</li>
            <li>Het urenboekingformulier toont door wie en wanneer de boeking voor het laatst is gewijzigd</li>
          </ul><p></p>
        </li>
        <li>
          v1.0 - Februari 2019
          <ul>
            <li>Rapportage functie beschikbaar voor alle gebruikers</li>
            <li>Rapport selectiescherm overzichtelijker gemaakt</li>
            <li>Urenkaart toegevoegd aan medewerker rapportage</li>
          </ul>
        </li>
      </ul>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'About',
  data() {
    return {
    }
  }
}
</script>